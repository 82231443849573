<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://leasing-trade.ru" class="auth__header__link" target="_blank">Вернуться на сайт</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
      <a href="https://leasing-trade.ru" class="auth__header__link auth__header__link--hidden">Вернуться на сайт</a>
    </div>
    <div class="auth__body">
      <form class="auth__form" @submit="onSubmitAuth">
        <h1 class="auth__form__title">Вход</h1>
        <div class="auth__form__input">
          <label for="email">E-mail</label>
          <input type="email" v-model="email" id="email" placeholder="E-mail" autocomplete="username">
        </div>
        <div class="auth__form__input">
          <label for="password">Пароль</label>
          <input type="password" v-model="password" id="password" placeholder="Пароль">
        </div>
        <div class="auth__form__input" v-if="isEmployeeModel && isSendedAuthKey">
          <label for="authKey">Одноразовый код подтверждения выслан на указанный email. Пожалуйста, проверьте почту</label>
          <input type="text" v-model="authKey" id="authKey" placeholder="Код подтверждения">
        </div>
        <div class="auth__form__actions">
          <button class="btn" v-if="!isSendedAuthKey && isEmployeeModel" @click="sendAuthKey" id="submit" type="submit">Вход</button>
          <button class="btn" v-else @click="loginUser" id="submit" type="submit">Вход</button>
          <router-link :to="{ name: 'registration'}" class="link">Регистрация</router-link>
          <router-link :to="{ name: 'lostpass'}" class="link">Забыли пароль?</router-link>
        </div>
      </form>
      <div class="auth__body__back">
        <AuthBack/>
      </div>
    </div>
  </div>
</template>

<script>
import LOGIN_USER from '../graphql/mutations/LoginUser.gql'
import GraphQLHelper from '../helpers/GraphQLHelper'
import AuthBack from '../components/svg/AuthBack'
import GEN_KEY from '../graphql/mutations/AuthKeyGenerate.gql'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Auth',
  data: () => {
    return {
      email: '',
      password: '',
      authKey: '',
      isSendedAuthKey: false
    }
  },
  components: {
    AuthBack
  },
  computed: {
    isEmployeeModel () {
      // eslint-disable-next-line no-useless-escape
      return /\@leasing-trade.ru/ig.test(this.email)
    }
  },
  methods: {
    async sendAuthKey () {
      if (!this.email) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите email'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: GEN_KEY,
        variables: {
          email: this.email
        },
        update: (store, { data: { authKeyGenerate } }) => {
          this.isSendedAuthKey = true
          this.$notify({
            group: 'lsg-notify',
            text: 'Одноразовый код подтверждения выслан на указанный email. Пожалуйста, проверьте почту'
          })
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async loginUser () {
      if (!this.email) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите email' + (!this.password ? ' и пароль' : '')
        })
        return
      }
      if (!this.password) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите пароль'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: LOGIN_USER,
        variables: {
          email: this.email,
          password: this.password,
          key: this.authKey
        },
        update: (store, { data: { loginUser } }) => {
          this.$store.dispatch({
            type: 'setAuthToken',
            token: loginUser
          }).then(async () => {
            await this.$router.push({ name: 'home' })
              .then(() => {
                this.$router.go()
              })
          })
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onSubmitAuth (event) {
      event.preventDefault()
    }
  },
  mounted () {
    var input = document.getElementById('submit')
    input.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        document.getElementById('submit').click()
      }
    })
  }
}
</script>
